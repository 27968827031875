@media (max-width: 480px) {


}

@media (max-width: 767px) {

     
    .primary-btn,
    .secondary-btn {
   
        padding: 14px 40px;

    }

    .big-numbers {
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
    }

.big-numbers h2 {

    margin-bottom: 40px;

}

.spacing.mb-hide{

    display: none;

}

.partner-logo{

    flex-direction: column;

}

.partner-logo li{

padding: 1rem 0;

}

.social {
 
    justify-content: center;
  
}

.copyright{

    text-align: center;

}

.about-ul{

    column-count: 1;

   }

   .roadmap {
    flex-direction: column;
    margin: 50px 14em 50px 0;
}

.roadmap-hr {
    width: 1px;
    height: 150px!important;
    display: inline-block;
    opacity: 1!important;
}

.roadmap-circle {
    width: 50px;
    height: 50px;
}

.roadmap-item p {
    left: 120%;
    font-size: .9em;
    width: 225px;
    top: auto;
    text-align: left;
}

.custom-checkbox {
    font-size: 12px;
}

.custom-checkbox input {
    width: 10px;
    height: 10px;
    margin-right: 5px!important;
}
 
.conditions{

    padding: 20px;

}



}

@media (max-width: 768px) {


   
    .sidenav .menu-list{

        width: 70%;

    }

    .sidenav .menu-list a {
        font-size: 1.5rem;
    }
    



}

@media (max-width: 991px) and (min-width: 768px) {

    .roadmap-item p {
        width: auto;
    }

}

@media(max-width:991px){

    .custom-nav{

        background-color:var(--bg);
        padding: 1rem 0 !important;

    }

   .closebtn{

    position: absolute;
    top: 0;
    right: 0;
    font-size: 36px;
    text-decoration: none;
    color: var(--white);
    padding: 0 30px !important;
    display: block;
  
  }

   .opennav{

    display: block;
    color: var(--white);
    font-size: 22px;
  
  }

  .sidenav{
    height: 100%;
    width: 0;
    position: fixed;
    background-color: var(--bg);
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.2s;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: flex-start;
    padding-top: 80px;
  }


    .custom-nav .navbar-nav{

        align-items: flex-start;

    }

    .custom-nav .navbar-nav a{

        padding: 10px 20px;

    }

    .nav_bar {
        
        flex-direction: column;
        align-items: flex-start;
    }

    .nav_bar li{

       padding:15px;;

    }

    .main{

        padding-top: 5rem;
        padding-bottom: 5rem;
        height: auto;

    }

    .flip-timer {
       
        --fcc-digit-block-width: 25px; /* digit card's width */
        --fcc-digit-block-height: 70px; /* digit card's height */
        --fcc-digit-font-size: 40px; /* font size of digit */
        --fcc-digit-color: var(--black); /* color of digit */
        --fcc-background: var(--white); /* digit card's background */
        --fcc-label-color: var(--grey); /* time label's color */
        --fcc-divider-color: var(--black); /* divider's color */
        padding: 20px 0;
      }

    .flip-timer ._2hRXr {
        margin-left: 0px;
        margin-right: 0px;
    }

    .watch-video{

        display: none;

    }

    .section-title{

        margin-bottom: 40px;

    }


    .payment-method{

        margin-top: 20px;

    }

    .ido-box {
        margin-bottom: 30px;
    }

    .staking-dropdown {
        text-align: center;
        margin: 3rem 0;
    }

    .ido-box .d-flex{

        flex-direction: column;

    }

    .conditions {

        width: 100%;
    }
    
    .conditions {
        margin-right:0 !important;
        margin-bottom: 20px;
    }

}

@media (max-width: 1023px) and (min-width: 992px) {

    .primary-btn,
    .secondary-btn {
   
        padding: 14px 40px;

    }


    .flip-timer {
       
        --fcc-digit-block-width: 28px; /* digit card's width */
    
      }

    .main{

        height: auto;
        padding-top: 5rem;
        padding-bottom: 5rem;
    
    }
    

}

@media (max-width: 1280px) and (min-width: 1024px) {

    .primary-btn,
    .secondary-btn {
   
        padding: 14px 40px;

    }


    .flip-timer {
       
        --fcc-digit-block-width: 28px; /* digit card's width */
    
      }


.main{

    height: auto;
    padding-top: 5rem;
    padding-bottom: 5rem;

}

.conditions {
    width: 30%;
}


}

@media (max-width: 1400px) and (min-width: 1200px) {

  
}

@media(min-width: 1440px) {
}